import styled from 'styled-components';


export const Area = styled.div`
    display:flex;
    background-color:#CCC;
    height:320px;
    justify-content:center;
    align-items:center;

`;
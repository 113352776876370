import React,{ useState, useEffect, useRef } from 'react';
import { Container, Check } from './styled';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import DatePicker from "react-datepicker";
import {LoadTela, Loading} from '../../components/Loading';
import { TELMask, CELMask, CEPMask, UFMask, cpfMask, rgMask } from '../../components/Mask';
import { Link } from 'react-router-dom';
import {parseISO, format, parse} from 'date-fns';
import useSalatoDeliveryAPI,{ IMAGE} from '../../helpers/SalatoAPI';
import { PageContainer, ErrorMessage, SucessoMessage, PageTitle } from '../../components/MainComponents';

let timer;
const Concurso = (props) => {
    const API = useSalatoDeliveryAPI();
    const CampoInputFile = useRef();
    const CampoTextArea = useRef();
    const [jwt, setJwt] = useState('');
    const [hash, setHash] = useState('');
    const [Nome, setNome] = useState('');
    const [Email, setEmail] = useState('');
    const [Telefone, setTelefone] = useState('');
    const [DataNasc, setDataNasc] = useState();
    const [CEP, setCEP] = useState('');
    const [NmEndereco, setNmEndereco] = useState('');
    const [DsLogradouro, setDsLogradouro] = useState('');
    const [NrNumero, setNrNumero] = useState('');
    const [DsBairro, setDsBairro] = useState('');
    const [DsCidade, setDsCidade] = useState('');
    const [CdUF, setCdUF] = useState('');
    const [DsPontoDeReferencia, setDsPontoDeReferencia] = useState('');
    const [RG, setRG] = useState('');
    const [Aceito, setAceito] = useState(false);
    const [Stload, setStLoad] = useState(false);
    const [uri, setUri] = useState('');
    const [msgSucess, setMsgSucess] = useState('');
    const [msgError, setMsgError] = useState('');
    const [disable, setDisable] = useState(false);
    const [LoadEmail, setLoadEmail] = useState(false);
    const [DsIdeia, setDsIdeia] = useState('');

    const HandleEnviar = async (e) =>{
        e.preventDefault();
        setStLoad(true);

        if (jwt === ''){
            const info = await API.insertClienteConcurso(
                Nome,
                Email,
                Telefone,
                DataNasc,
                RG
            )
            if(info.error){
                setMsgError(info.error);
            }else{
                setJwt(info.jwt);
                InsertEndereco(info.jwt);
            }
        }else{
            const info = await API.updateClienteConcurso(
                jwt,
                Nome,
                Email,
                Telefone,
                DataNasc,
                RG
            )
            if(info.error){
                setMsgError(info.error);
            }else{
                setJwt(jwt);
                InsertEndereco(jwt);
            }
        }
    }

    const InsertEndereco = async (token) => {
        const end = await API.insertEndereco(
            token,
            DsLogradouro,
            DsBairro,
            DsCidade,
            NrNumero,
            CEP,
            CdUF,
            null,
            NmEndereco,
            DsPontoDeReferencia
        )
        if (end.error){
            setMsgError(end.error);
        }else{
            upLoadVideo(token);
        }
    }

    const upLoadVideo = async(token) => {
        const json = await API.uploadVideo(
            token,
            uri,
            'Minha Coxinha',
            true,
            true,
            DsIdeia
        )
        if(json.error){
            setMsgError(json.error);
        }else{
            setMsgSucess(json.dados);
            limpaCampos();
        }
        setStLoad(false);
    }

    const checkEmailExiste = async () => {

        setLoadEmail(true);
        setMsgError('');
        setTimeout(async()=>{
            const json = await API.getEmailExistente(
                Email
            )
    
            if (json.jwt){
                setJwt(json.jwt);
            }
            setLoadEmail(false);
        },700)

    }

    const limpaCampos = () => {
        setNome('');
        setEmail('');
        setTelefone('');
        setDataNasc('');
        setCEP('');
        setNmEndereco('');
        setDsLogradouro('');
        setNrNumero('');
        setDsBairro('');
        setDsCidade('');
        setCdUF('');
        setDsPontoDeReferencia('');
        setRG('');
        setAceito(false);
        setUri('');
        setDsIdeia('');
        CampoInputFile.current.value = '';
        CampoTextArea.current.value = '';
    }

    /*useEffect(() =>{
        if (timer){
            clearTimeout(timer)
        }
        timer = setTimeout(async()=>{
            setStLoad(true);
            if (CEP.length >= 10 ){

                const json = await API.getEnderecoCEP(CEP);
                console.log(json);
                if (json.logradouro){
                    setDsLogradouro(`${json.tipo_logradouro} ${json.logradouro} `);
                    setDsBairro(json.bairro);
                    setDsCidade(json.cidade);
                    setCdUF(json.uf);

                }
            }
            setStLoad(false);
        },1)

    },[CEP]);*/

    return(
        <PageContainer>
            <PageTitle>Concurso Minha Coxinha  </PageTitle>
            <Container>
                <div className="AreaTopo">
                    <div className="AreaLogo"></div>
                    <h2>Regras do Concurso!!!!</h2>
                    <div className="AreaLogo">
                        <img className="logoMinhaCoxinha"src={require('../../assets/images/logoMinhaCoxinha.png')}/>
                    </div>
                </div>
                <h1 title="Titulo">O Video não é obrigatório</h1>
                <h1 title="Titulo">Não tem taxa de inscrição</h1>
                <label>
                    <h3>Inscrição:</h3>
                    <p>O concurso será aberto à pessoas de todas as faixas etárias. Menores de idade deverão ter um termo de consentimento assinado pelos pais.</p>
                    <p>Serão selecionados 5 participantes através dos dados cadastrais enviados.</p>
                    <p>Serão 8 jurados (popular) clientes consumidores assíduos das nossas lojas selecionados pelas nossas atendentes, e 3 jurados (técnicos) convidados pela Salato.</p>
                    <p>As notas dos jurados (popular) serão dadas de 1 a 5 na qual 5 e a nota máxima.</p>
                    <p>As notas dos jurados (técnico) serão dadas de 1 a 10 na qual 10 e a nota máxima.</p>
                    <p>Após serão somados todos os pontos e assim teremos o resultado final.</p>
                    <p>As coxinhas deverão ser produzidas na própria casa do participante, sendo levada para o local onde ocorrera a votação ainda sem fritar.</p>
                    <p>Os custos com insumos serão por conta dos participantes.</p>
                    <p>Deve se levar o mínimo de 15 coxinhas no tamanho de 40 gramas no dia, porem por segurança indicamos levar um numero maior de coxinhas.</p>
                    <p>O sabor das coxinhas e livre(salgada, doce, amarga, acida ou umami) kakakakakakakakkak, use a criatividade e nos surpreenda!!</p>
                    <p>Serão avaliados os seguintes as aspectos: Sabor, Crocancia, Equilibrio entre o recheio e a massa!!</p>
                </label>
                <label className="LabelArea">
                    <h3>Premiacao:</h3>
                    <p>1 colocado: R$ 1.200,00 (Um mil e duzentos reais)</p>
                    <p>2 colocado: R$ 600,00 (Seiscentos reais)</p>
                    <p>3 colocado: R$ 200,00 (Duzentos reais)</p>
                </label>
                <label className="LabelArea">
                    <h3>Local:</h3>
                    <p>Será realizado em nossa unidade de <strong>Piabeta</strong>.</p>
                    <p>Lá é uma loja mais espaçosa e utilizaremos todos os protocolos de segurança para a COVID-19.</p>
                </label>
                <label className="LabelArea">
                    <h3>Data:</h3>
                    <p>Dia 13/08/2021 as 18:30.</p>
                </label>
                <label>
                    <p>A Salato se compromete a comprar desses 3 participantes as coxinhas durante um período de 3 meses para estimular o empreendimento e também a estar trocando ideias para ajudar pessoas que queiram desenvolver o negócio. As compras serão pagamento a vista e de acordo com a demanda em nossas lojas.</p>
                    <p>Nota: A compra está atrelada à analise dos custo.</p>
                    <p>Eu, {Nome}, Portador do RG:{rgMask(RG)}, autorizo formalmente à Salato divulgar meu nome e usar minha imagem, nos termos deste Regulamento.</p>
                </label>
                <video width="320" height="240" controls="controls">
                    <source src={IMAGE+"/Video/videominhacoxinha.mp4"} type="video/mp4" />                
                </video>
                <h3 className="TopoInscr">Ficha de Inscrição</h3>
                {false && <ErrorMessage>As incrições para o concurso abrem partir do dia 25/06/2021!!! Enquanto isso, ja vai testando suas receitas e gravando seu video!!! E agora, bora colocar o umbigo no fogão!!!</ErrorMessage>}
                {msgSucess &&  <SucessoMessage>{msgSucess}</SucessoMessage>}
                {msgError && <ErrorMessage>{msgError}</ErrorMessage>}
                <form onSubmit={HandleEnviar} enctype="multipart/form-data">
                    <label className="area">
                        <div className="area--title">
                            <div>Nome Completo </div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input
                                type="text"
                                value={Nome}
                                onChange={(e)=>setNome(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>RG</div>
                            <Check>*</Check>
                        </div>
                        
                        <div className="area--input">
                            <input 
                                type="tel" 
                                value={rgMask(RG)}
                                onChange={(e)=>setRG(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>E-mail</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input
                                type="email"
                                value={Email}
                                onChange={(e)=>setEmail(e.target.value)}
                                onBlur={checkEmailExiste}
                                required
                                disabled={disable}
                            />
                            {LoadEmail &&
                                <div className="Loading">
                                    <Loading height={15} width={15}></Loading>
                                </div>
                            }
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Telefone</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="tel" 
                                value={CELMask(Telefone)}
                                onChange={(e)=>setTelefone(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <div className="area">
                        <div className="area--title">
                            <div>Data de Nascimento</div>
                            <Check>*</Check>
                        </div>
                        <DatePicker
                            className="AreaDatePicker"
                            selected={DataNasc}
                            onChange={(e)=>setDataNasc(e)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            mo
                            required
                            locale="pt-BR"
                            disabled={disable}
                        />
                    </div>       
                    <label className="area">
                        <div className="area--title">
                            <div>CEP</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="tel"
                                value={CEPMask(CEP)}
                                onChange={(e)=>setCEP(CEPMask(e.target.value))}
                                required
                                disabled={disable}
                            />
                            <div className="load">

                            </div>
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Nome do Endereço</div>
                            <Check>*</Check>
                        </div>
                        
                        <div className="area--input">
                            <input 
                                type="text" 
                                value={NmEndereco}
                                onChange={(e)=>setNmEndereco(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Logradouro</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="text" 
                                value={DsLogradouro}
                                onChange={(e)=>setDsLogradouro(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Numero</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="tel" 
                                value={NrNumero}
                                onChange={(e)=>setNrNumero(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Bairro</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="text" 
                                value={DsBairro}
                                onChange={(e)=>setDsBairro(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Cidade</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="text" 
                                value={DsCidade}
                                onChange={(e)=>setDsCidade(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>UF</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="text" 
                                value={UFMask(CdUF)}
                                onChange={(e)=>setCdUF(UFMask(e.target.value))}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Ponto de Referência</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="text" 
                                value={DsPontoDeReferencia}
                                onChange={(e)=>setDsPontoDeReferencia(e.target.value)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Video</div>
                            <Check></Check>
                        </div>
                        <div className="area--input">
                            <input 
                                type="file" 
                                ref={CampoInputFile}
                                accept=".mp4, .MOV, .FLV, .AVI, .WMV"
                                onChange={(e)=>{
                                    setUri(e.target.files[0])
                                }}
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--title">
                            <div>Conte-nos a sua idéia de coxinha</div>
                            <Check>*</Check>
                        </div>
                        <div className="area--input">
                            <textarea 
                                ref={CampoTextArea}
                                onChange={(e)=>setDsIdeia(e.target.value)}
                                required
                            >
                                {DsIdeia}
                            </textarea>
                        </div>
                    </label>
                    <label className="check">
                        <div className="area--title">
                            <div>Aceito todos os termos do concurso</div>
                            <Check>*</Check>
                        </div>
                        <div className="check--area">
                            <input 
                                type="checkbox" 
                                checked={Aceito}
                                onChange={(e)=>setAceito(e.target.checked)}
                                required
                                disabled={disable}
                            />
                        </div>
                    </label>
                    <label className="area">
                        <div className="area--btn">
                            <button disabled={disable}>Participar</button>
                        </div>
                    </label>
                </form>
                {Stload &&  <LoadTela visible={Stload} color="#FF0000" height="60px" width="60px" />}
            </Container>
        </PageContainer>
    )
}

const mapStateToProps = (state) => {
    return{
        DadosCliente:state.user.DadosCliente,
        DadosEndereco:state.user.DadosEndereco
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setNome:(nome)=>dispatch({tupe:'SET_NOME', payload:{nome}})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Concurso);
import styled from 'styled-components';

export const Check = styled.div`
    color:#FF0000;
    margin-left:5px;
`;

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    padding:20px;
    align-items:center;
    height:auto;
    width:100%;
    background-color:#FFF;
    border-radius:5px;
    border:1px solid #CCC;
    box-shadow:2px 1px 4px 1px #999;
    margin-bottom:20px;

    .LabelArea{
        width:100%;
    }

    h1, h3 [title~=Titulo]{
        color:#FF0000;
        text-align:center;
    }


    .AreaTopo{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        width:100%;

        h2{
            width:100%;
            text-align:center;
        }

        .AreaLogo{
            display:flex;
            width:100%;
            justify-content:flex-end;
           
        }
        .logoMinhaCoxinha{
            width:200px;
            heigth:auto;
            resize-mode:contain;
        }
    }

    .TopoInscr{
        color:#FF0000;
    }

    form{
        display:flex;
        justify-content:center;
        align-items:flex-end;
        flex-direction:column;
        background-color:#FFF;
        width:auto;
        height:auto;
        border-radius:10px;
        opacity:0.94;
        
        .AreaDatePicker{
            color:#000;
            background: url(https://salato.com.br/backEndSalato/Images/ico-seta-appearance.gif) no-repeat #FFF;  /* Imagem de fundo (Seta) */
            background-position: 100% center;  /*Posição da imagem do background*/
            height:29px;
            width:500px;
            border-radius:3px;
            outline:0;
            border:1px solid #DDD;
            font-size:14px;
            margin-left:10px;
            padding:5px;
        }
        .Loading{
            position:absolute;
            padding:4px;
        }
        .check{
            display:flex;
            flex-direction:row;
            display:flex;
            justify-content:center;
            align-items:center;
            margin-right:50px;
            margin:2px;

            .check--area{
                display:flex;
                justify-content:flex-start;
                width:500px;
                margin-left:10px;
            }

            .area--title{
                width:auto;
                display:flex;
                flex-direction:row;
            }
        }

        .area--email{
            display:flex;
            width:520px;
        }
        .area{
            display:flex;
            flex-direction:row;
            display:flex;
            justify-content:center;
            align-items:center;
            margin-right:50px;
            margin:2px;
            
            .area--input{
                display:flex;
                justify-content:flex-end;
                align-items:flex-end;
                
                input{
                    width:500px;
                    font-size:14px;
                    padding:5px;
                    border:1px solid #DDD;
                    border-radius:3px;
                    outline:0;
                    transition:all ease .4s;
                    margin-left:10px;

                }

                textarea{
                    width:500px;
                    height:70px;
                    font-size:14px;
                    padding:5px;
                    border:1px solid #DDD;
                    border-radius:3px;
                    outline:0;
                    transition:all ease .4s;
                    margin-left:10px;
                    resize: none;
                }

            }

            .area--title{
                display:flex;
                flex-direction:row;
            }


            .area--data{
                display:flex;
                border-radius:3px;
                margin-left:10px;

                input{
                    color:#000;
                    background: url(https://salato.com.br/backEndSalato/Images/ico-seta-appearance.gif) no-repeat #FFF;  /* Imagem de fundo (Seta) */
                    background-position: 100% center;  /*Posição da imagem do background*/
                    height:29px;
                    width:500px;
                    border-radius:3px;
                    outline:0;
                    border:1px solid #DDD;
                    font-size:14px;
                }
            }

            .area--btn{
                display:flex;
                height:60px;
                justify-content:flex-end;
                align-items:flex-end;
                
                button {
                    display:flex;
                    width:120px;
                    height:30px;
                    justify-content:center;
                    align-items:center;
                    background-color:#FF0000;
                    border:0;
                    outline:0;
                    padding:5px 10px;
                    border-radius:4px;
                    color:#FFFF00;
                    font-size:15px;
                    cursor:pointer;
                    margin-left:5px;
    
                    &:hover {
                        background-color:#CD3700;
                    }
                }
            }
            .area--areaButton1{
                margin-left:10px;
                display:flex;
                width:300px;

                .area--btn{
                    height:30px;
                    display:flex;
                    margin:5px;
                    width:35%;
                    justify-content:center;
                    align-items:center;
                    cursor:pointer;
                    color:#FFF;
                    border-radius:5px;
                }

                .btnAtivo{
                    transition:all ease .5s;
                    background-color:#FF0000;
                }

                .btnInativo{
                    transition:all ease .5s;
                    background-color:#CCC;
                }
            }
            .area--areaButton2{
                margin-left:10px;
                display:flex;
                width:300px;
                flex-wrap:wrap;

                .area--btn{
                    height:30px;
                    display:flex;
                    margin:5px;
                    width:30%;
                    justify-content:center;
                    align-items:center;
                    cursor:pointer;
                    color:#FFF;
                    border-radius:5px;
                }

                .btnAtivo{
                    transition:all ease .5s;
                    background-color:#FF0000;
                }

                .btnInativo{
                    transition:all ease .5s;
                    background-color:#CCC;
                }
            }
        }
    }
    .area--footer{
        display:flex;
        justify-content:flex-end;
        align-items:flex-end;
        width:600px;

        .area--sub{

            .area--btn{
                display:flex;
                height:60px;
                justify-content:flex-end;
                align-items:flex-end;
                
                button {
                    display:flex;
                    width:120px;
                    height:30px;
                    justify-content:center;
                    align-items:center;
                    background-color:#0089FF;
                    border:0;
                    outline:0;
                    padding:5px 5px;
                    border-radius:4px;
                    color:#FFF;
                    font-size:15px;
                    cursor:pointer;
                    margin-right:5px;
                    margin-bottom:10px;
    
                    &:hover {
                        background-color:#006FCE;
                    }
                }
            }
        }
        
    }
    


@media(max-width:800px){
    height:auto;
    .AreaTopo{
        flex-direction:column;

        .AreaLogo{
            justify-content:center;
        }
    }

    form{
        justify-content:center;
        align-items:center;
        flex-direction:column;
        width:100%;

        .react-datepicker-wrapper{
            width:100%;
        }
        .AreaDatePicker{
            width:100%;
            margin-left:0px;
            
        }
        .check{
            width:100%;
            .check--area{
                width:40px;

                input{
                    width:20px;
                    height:20px;
                }
            }

            .area--title{
                width:100%;
                display:flex;
                flex-direction:row;
            }
        }

        .area{
            justify-content:flex-start;
            align-items:flex-start;
            flex-direction:column;
            margin-right:0px;
            width:100%;

            .area--title{
                width:100%;
            }

            .area--input{
                width:100%
                input{
                    width:100%;
                    margin-left:0;
                }
                textarea{
                    width:100%;
                    margin-left:0;
                }
            }

            .area--data{
                width:100%;
                margin-left:0px;
                .react-datepicker-wrapper{
                    width:100%;
                }
                .area--datapicker{
                    width:100%;
                }
                input{
                    width:100%;
                }
            }
        }
    }
}

@media(max-width:350px){
    height:auto;
}
`;